import { render, staticRenderFns } from "./Items.vue?vue&type=template&id=5b13ff96"
import script from "./Items.vue?vue&type=script&lang=js"
export * from "./Items.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QIcon,QTable,QTr,QTh,QTd});
